import React, { useState, useEffect } from 'react';
import './HomePage.css';
import MeritSection from './MeritSection';
import FlowSection from './FlowSection';
import CurriculumSection from './CurriculumSection';
import CourseSection from './CourseSection';
import ConceptSection from './ConceptSection';

import topImage01 from '../assets/images/top/top-image-01.jpeg';
import topImage02 from '../assets/images/workstyles/freelance-image-02.jpeg';
import topImage03 from '../assets/images/workstyles/freelance-image-04.jpeg';
import topImage04 from '../assets/images/features/receiveajob-image-01.jpeg';
import ContactButtonSection from './ContactButtonSection';
import StrengthsSection from './StrengthsSection';


const HomePage = () => {
  const images = [topImage01, topImage02, topImage03, topImage04];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [visibleLines, setVisibleLines] = useState(0); // 表示されている行数
  const texts = [
    "自由な",
    "働き方を",
    "手にいれる"
  ];

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex(nextImageIndex);
      setNextImageIndex((nextImageIndex + 1) % images.length);
    }, 4000); // 6秒ごとに画像を切り替え

    const textInterval = setInterval(() => {
      setVisibleLines((prevLines) => (prevLines + 1 <= texts.length ? prevLines + 1 : texts.length));
    }, 1000); // 1秒ごとにテキストを1行ずつ表示

    return () => {
      clearInterval(imageInterval);
      clearInterval(textInterval);
    };
  }, [nextImageIndex, images.length, texts.length]);

  return (
    <div>
      <div className='hero-section'>
        <div className="hero-image-container">
          {/* 2枚の画像を重ねて表示。currentImageIndexがactive、nextImageIndexが次にフェードイン */}
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`hero-image ${index === currentImageIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <div className='hero-text'>
          {texts.slice(0, visibleLines).map((line, index) => (
            <h1 key={index} className="fade-in-line">{line}</h1>
          ))}
        </div>
      </div>

      <StrengthsSection />
      <MeritSection />
      <FlowSection />
      <CurriculumSection />
      <CourseSection />
      <ConceptSection />
      <div className="top-contact-button-section">
        <ContactButtonSection />
      </div>
    </div>
  );
};

export default HomePage;