import React from 'react';
import './Features.css'; // CSS file
import topImageFeature from '../assets/images/features/featurespage-image-top-00.png'; // Adjust the image path accordingly
import ExperienceSection from './ExperienceSection';
import CertificateSection from './CertificateSection';
// import StudyAbroadSection from './StudyAbroadSection';
import ReceiveAJobSection from './ReceiveAJobSection';
import MetaverseStudyAbroadSection from './MetaverseStudyAbroadSection';
import MetaverseSection from './MetaverseSection';
// import LocalGovernmentSection from './LocalGovernmentSection';
// import HalfFarmerSection from './HalfFarmerSection';
import StartupSupportSection from './StartupSupportSection';
import ContactButtonSection from './ContactButtonSection';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Features = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className="feature-page">
      <div className="page-title">
        <h1>当校の特徴について</h1>
        <p>Features</p>
      </div>
      <div className="learning-image-container">
        <img src={topImageFeature} alt="特徴" className="top-image" />
      </div>
      <section id="experience-section">
        <ExperienceSection />   {/* Section for "Experience" */}
      </section>
      <section id="certificate-section">
        <CertificateSection />
      </section>

      {/*
      <section id="study-abroad-section">
        <StudyAbroadSection />
      </section>
      */}

      <section id="receive-a-job-section">
        <ReceiveAJobSection />
      </section>

      <section id="startup-support-section">
        <StartupSupportSection />
      </section>

      <section id="metaverse-study-abroad-section">
        <MetaverseStudyAbroadSection />
      </section>

      <section id="metaverse-section">
        <MetaverseSection />
      </section>

      {/* <section id="local-government-section">
        <LocalGovernmentSection />
      </section> */}

      {/*
      <section id="half-farmer-section">
        <HalfFarmerSection />
      </section> */}

      <div className="top-contact-button-section">
        <ContactButtonSection />
      </div>

    </div>
  );
};

export default Features;