import React from 'react';
import './AdmissionButtonSection.css';
// import { Link } from 'react-router-dom';

const AdmissionButtonSection = () => {
  return (
    <div className="admission-button-section">
        <h2 className='admission-button-title'>Admission</h2>
        <p className='admission-button-description'>入学と卒業の時期はお選びいただけます。</p>

        <div className="button-container">
            {/* 外部リンク */}
            <a href="https://forms.gle/wU5AjNmgMeLJDYnf8" target="_blank" rel="noopener noreferrer" className="contact-button">
            <h2 className="large-text">入学申し込み</h2>
            </a>
        </div>
    </div>
  );
};

export default AdmissionButtonSection;