import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// 翻訳データ
const resources = {
  en: {
    translation: {
      greeting: "Welcome to our website",
      description: "This is a multilingual site",
    },
  },
  fr: {
    translation: {
      greeting: "Bienvenue sur notre site Web",
      description: "Ceci est un site multilingue",
    },
  },
};

i18n
  .use(LanguageDetector) // ブラウザの言語を検出
  .use(initReactI18next) // react-i18nextを初期化
  .init({
    resources,
    fallbackLng: "en", // デフォルト言語
    interpolation: {
      escapeValue: false, // Reactは自動でエスケープするため不要
    },
  });

export default i18n;