import React from 'react';
import './StartupSection.css';

// 画像をインポート
import FeaturesSectionImage01 from '../assets/images/learningcontent/features-image-01.jpeg';
import FeaturesSectionImage02 from '../assets/images/workstyles/startupsupport-image-05.jpeg';
import FeaturesSectionImage03 from '../assets/images/workstyles/startup-image-01.jpeg';
import FeaturesSectionImage04 from '../assets/images/workstyles/startupsupport-image-06.jpeg';
import FeaturesSectionImage05 from '../assets/images/workstyles/freelance-image-04.jpeg';

const StartupSection = () => {
  const features = [
    { id: 1, title: '起業支援プログラム', description: '起業するためのマインドセット、プレゼン資料の作り方などが身につきます。', image: FeaturesSectionImage01 },
    { id: 2, title: '資金調達', description: '起業支援プログラムで内容が良ければ、VCや金融機関から出資の機会が得られます。', image: FeaturesSectionImage02 },
    { id: 3, title: '自治体連携', description: '自治体と連携することで、ビジネス課題を把握できたり、地域コミュニティに参加することができます。', image: FeaturesSectionImage03 },
    { id: 4, title: '移住', description: '移住に関する補助は数多く存在するので、起業のハードルが下がります。', image: FeaturesSectionImage04 },
    { id: 5, title: '仲間を見つける', description: '在校生と卒業生によるコミュニティがあるため、仲間を見つけやすいです。', image: FeaturesSectionImage05 },
  ];

  return (
    <div className='features-section'>
      <h2 className='features-title'>Start up</h2>
      <p>起業するためのサポートがあります。<sup>＊1</sup></p>
      <div className='features-grid'>
        {features.map((features) => (
          <div key={features.id} className='features-item'>
            <img src={features.image} alt={features.title} className='features-image' />
            <h3><i class="fa-solid fa-circle"></i>{features.title}</h3>
            <p>{features.description}</p>
          </div>
        ))}
      </div>
      <h3 className='startup-slide'>＊１. 起業育成プログラムは、オプションとなります。<br />別途費用がかかります。</h3>

      <div className="button-container">
            {/* 外部リンク */}
            <a href="https://www.canva.com/design/DAGWicOiff0/9JtrBLgsKsqvf7zeXJT0Gg/view?utm_content=DAGWicOiff0&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h089a76fa8c&fbclid=IwY2xjawHQ9PdleHRuA2FlbQIxMAABHSwzuBfDarK2hFjM7xYdkSohYCaTxoToLkXh-4DSg3A6v6K7oanNYIZ5hg_aem_TAwYIbiAVAjhT1P4vuG1Aw#1" target="_blank" rel="noopener noreferrer" className="contact-button">
            <h2 className="large-text">詳細はこちら</h2>
            </a>
      </div>

    </div>
  );
};

export default StartupSection;