// StrengthsSection.js

import React from 'react';
import './StrengthsSection.css';

// 画像をインポート
import LocalGovernmentImage01 from '../assets/images/top/Strengths-image-01.jpeg';
import LocalGovernmentImage02 from '../assets/images/features/featurespage-image-top-00.png';
import LocalGovernmentImage03 from '../assets/images/top/concept-image-01.jpeg';

const StrengthsSection = () => {
  const strengthsFeatures = [
    { id: 1, title: 'ChatGPTでプログラミング', description: '未経験の方でも、GPTの力を借りれば急速に開発能力をあげることができます。基礎知識と経験値を手に入れることで、今後のAI時代を生き抜くことができます。', image: LocalGovernmentImage01 },
    { id: 2, title: 'メタバースでの講義', description: 'どこからでもリアルと同じように、講義を受けたり開発することができます。通学中だけでなく卒業後も継続してメタバースに参加することができ、交流ができます。', image: LocalGovernmentImage02 },
    { id: 3, title: '自治体連携', description: '様々な生活スタイルが選択できます。2拠点生活やプチ移住もあり、大きな家での豊かな生活や生活コストを抑えることができます。同時に地方創生に貢献することができます。', image: LocalGovernmentImage03 },
  ];

  return (
    <div className='strengths-section'>
      <h2 className='strengths-title'>Strengths</h2>
      <p className='strengths-description'>当校の強みをご紹介します。</p>

      <div className='strengths-container'>
        {strengthsFeatures.map((feature) => (
          <div key={feature.id} className='strengths-item'>
            <img src={feature.image} alt={feature.title} className='strengths-image' />
            <h3><i className='fa-solid fa-circle'></i>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StrengthsSection;
