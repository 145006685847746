// Header.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // スタイルシートを適用
import logoImage01 from '../assets/images/top/logo-icon7.png';
import menuImage01 from '../assets/images/learningcontent/learningcontent-image-top.jpeg';
import menuImage02 from '../assets/images/workstyles/workstyle-image-top.jpeg';
import menuImage03 from '../assets/images/features/featurespage-image-top-00.png';
import menuImage04 from '../assets/images/admission/admission-image-top-01.jpeg';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 950);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // スクロールイベントを監視して、スクロール位置をチェック
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // メニューの開閉を切り替える関数
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // ドロップダウンメニューの開閉を切り替える関数
  const toggleDropdown = (menuName) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

  // デスクトップの場合のhoverによるメニュー開閉
  const handleMouseEnter = (menuName) => {
    if (!isMobile) {
      setDropdownOpen((prevState) => ({
        ...prevState,
        [menuName]: true,
      }));
    }
  };

  const handleMouseLeave = (menuName) => {
    if (!isMobile) {
      setDropdownOpen((prevState) => ({
        ...prevState,
        [menuName]: false,
      }));
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="logo-section">
        <Link to="/">
          <img src={logoImage01} alt="ロゴ" className="logo" />
          <span className="school-name">メタバース情報工学学校</span>
        </Link>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
        <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
        <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
      </div>

      <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="/">ホーム</Link>
          </li>

          <li
            className={`dropdown ${dropdownOpen['learning-content'] ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter('learning-content')}
            onMouseLeave={() => handleMouseLeave('learning-content')}
            onClick={(e) => {
              toggleDropdown('Learning-content');
            }}
          >
            <Link
              to="/learning-content"
              className="menu-item"
              onClick={(e) => {
                if (isMobile) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown('learning-content');
                }
              }}>学習内容</Link>
            {dropdownOpen['learning-content'] && (
              <div className="mega-menu">
                <div className="mega-menu-content">
                  <div className="mega-menu-left">
                    <img src={menuImage01} alt="サブメニュー画像" className="mega-menu-image" />
                  </div>
                  <div className="mega-menu-right">
                    <ul className="mega-menu-columns">
                      <li className='chevron-right'><Link to="/learning-content#flow-section">入学から卒業までの流れ</Link></li>
                      <li className='chevron-right'><Link to="/learning-content#features-section">学校独自の特典</Link></li>
                      <li className='chevron-right'><Link to="/learning-content#video-contents-section">学習動画</Link></li>
                      <li className='chevron-right'><Link to="/learning-content#course-detail-section">コース</Link></li>
                      <li className='chevron-right'><Link to="/learning-content#cost-table-section">費用</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>

          <li
            className={`dropdown ${dropdownOpen['work-styles'] ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter('work-styles')}
            onMouseLeave={() => handleMouseLeave('work-styles')}
            onClick={(e) => {
              toggleDropdown('work-styles');
            }}
          >
            <Link
              to="/work-styles"
              className="menu-item"
              onClick={(e) => {
                if (isMobile) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown('work-styles');
                }
              }}>働き方</Link>
            {dropdownOpen['work-styles'] && (
              <div className="mega-menu">
                <div className="mega-menu-content">
                  <div className="mega-menu-left">
                    <img src={menuImage02} alt="サブメニュー画像" className="mega-menu-image" />
                  </div>
                  <div className="mega-menu-right">
                    <ul className="mega-menu-columns">
                      <li className='chevron-right'><Link to="/work-styles/#find-work-section">就職</Link></li>
                      <li className='chevron-right'><Link to="/work-styles/#startup-support-section">起業</Link></li>
                      <li className='chevron-right'><Link to="/work-styles/#freelance-section">フリーランス</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>

          <li
            className={`dropdown ${dropdownOpen['features'] ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter('features')}
            onMouseLeave={() => handleMouseLeave('features')}
            onClick={(e) => {
              toggleDropdown('features');
            }}
          >
            <Link
              to="/features"
              className="menu-item"
              onClick={(e) => {
                if (isMobile) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown('features');
                }
              }}>特徴</Link>
            {dropdownOpen['features'] && (
              <div className="mega-menu">
                <div className="mega-menu-content">
                  <div className="mega-menu-left">
                    <img src={menuImage03} alt="サブメニュー画像" className="mega-menu-image" />
                  </div>
                  <div className="mega-menu-right">
                    <ul className="mega-menu-columns">
                      <li className='chevron-right'><Link to="/features#experience-section">実務経験</Link></li>
                      <li className='chevron-right'><Link to="/features#certificate-section">資格</Link></li>
                      <li className='chevron-right'><Link to="/features#receive-a-job-section">仕事の提供</Link></li>
                      <li className='chevron-right'><Link to="/features#startup-support-section">起業支援</Link></li>
                      <li className='chevron-right'><Link to="/features#metaverse-study-abroad-section">メタバース英会話</Link></li>
                      <li className='chevron-right'><Link to="/features#metaverse-section">メタバース</Link></li>
                      {/* <li className='chevron-right'><Link to="/features#local-government-section">連携自治体</Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>

          <li
            className={`dropdown ${dropdownOpen['admission'] ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter('admission')}
            onMouseLeave={() => handleMouseLeave('admission')}
            onClick={(e) => {
              toggleDropdown('admission');
            }}
          >
            <Link
              to="/admission"
              className="menu-item"
              onClick={(e) => {
                if (isMobile) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown('admission');
                }
              }}>入学案内</Link>
            {dropdownOpen['admission'] && (
              <div className="mega-menu">
                <div className="mega-menu-content">
                  <div className="mega-menu-left">
                    <img src={menuImage04} alt="サブメニュー画像" className="mega-menu-image" />
                  </div>
                  <div className="mega-menu-right">
                    <ul className="mega-menu-columns">
                      <li className='chevron-right'><Link to="/admission#campaign-section">キャンペーン</Link></li>
                      <li className='chevron-right'><Link to="/admission#procedure-section">入学手順</Link></li>
                      <li className='chevron-right'><Link to="/admission#admission-button-section">入学申し込み</Link></li>
                      <li className='chevron-right'><Link to="/admission#school-meeting-section">学校説明会</Link></li>
                      {/* <li className='chevron-right'><Link to="/admission#web-meeting-section">Web説明会</Link></li> */}
                      <li className='chevron-right'><Link to="/admission#tuition-support-section">学費サポート</Link></li>
                      <li className='chevron-right'><Link to="/admission#contact-button-section">LINE相談・資料請求・お問い合わせ</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>

          <li className="contact-button">
            <a href="https://calendar.app.google/jGokojRqRxsTcYBN6" target="_blank" rel="noreferrer">無料個別相談</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;