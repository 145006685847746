import React from 'react';
import './StartupSupportSection.css';
import startupImage1 from '../assets/images/workstyles/startupsupport-image-01.jpeg';
import startupImage2 from '../assets/images/workstyles/startupsupport-image-02.jpeg';
import startupImage3 from '../assets/images/workstyles/startupsupport-image-03.jpeg';
import startupImage4 from '../assets/images/workstyles/startupsupport-image-04.jpeg';
import startupImage5 from '../assets/images/workstyles/startupsupport-image-05.jpeg';
import startupImage6 from '../assets/images/workstyles/startupsupport-image-06.jpeg';

const StartupSupportSection = () => {
  const startupData = [
    { number: '1', title: '自分の想いやビジョンに向き合う', description: '起業や社会課題解決において、自分自身の思いや価値観を深く掘り下げます。', image: startupImage1 },
    { number: '2', title: '地域課題の共有と解決案の作成', description: '地域の代表者等と協力し、実際に存在する地域の課題について共有します。その後、解決案を考えます。', image: startupImage2 },
    { number: '3', title: 'ビジネスプラン作成ワークショップ', description: 'ブレインストーミングで得たアイデアを基に、具体的なビジネスプランを策定します。', image: startupImage3 },
    { number: '4', title: 'ビジネスピッチイベント', description: '完成したビジネスプランを地域代表者や投資家に向けて発表します。', image: startupImage4 },
    { number: '5', title: 'VCや金融機関からの資金調達', description: '内容によっては、VCや地域の金融機関からの資金調達が可能です。', image: startupImage5 },
    { number: '6', title: '移住を伴う起業', description: '移住を検討している方は、さらに資金調達がやりやすく、起業コストの削減も見込めます。', image: startupImage6 },
  ];

  return (
    <div className="startup-support-section">
      <h2 className="startup-support-title">Startup Support</h2>
      <p className="startup-support-description">当校が提供している、起業支援プログラムをご紹介します。</p>
      <div className="startup-container">
        {startupData.map((item, index) => (
          <div key={index} className="startup-block">
            <div className="startup-image-container">
              <img src={item.image} alt={item.title} className="startup-image" />
            </div>
            <div className="number-circle">{item.number}</div>
            <div className="startup-text-container">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}

      </div>
      <h3>＊１. 起業育成プログラムは、オプションとなります。<br />別途費用がかかります。</h3>

      <div className="button-container">
          {/* 外部リンク */}
          <a href="https://www.canva.com/design/DAGWicOiff0/9JtrBLgsKsqvf7zeXJT0Gg/view?utm_content=DAGWicOiff0&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h089a76fa8c&fbclid=IwY2xjawHQ9PdleHRuA2FlbQIxMAABHSwzuBfDarK2hFjM7xYdkSohYCaTxoToLkXh-4DSg3A6v6K7oanNYIZ5hg_aem_TAwYIbiAVAjhT1P4vuG1Aw#1" target="_blank" rel="noopener noreferrer" className="contact-button">
            <h2 className="large-text">詳細はこちら</h2>
          </a>
      </div>
    </div>
  );
};

export default StartupSupportSection;