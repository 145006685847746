import React from 'react';
import './MetaverseStudyAbroadSection.css';

// 画像をインポート
import FeaturesSectionImage01 from '../assets/images/features/metaverse-study-abroad-image-01-03.png';
import FeaturesSectionImage02 from '../assets/images/features/metaverse-study-abroad-image-02-02.png';
import FeaturesSectionImage03 from '../assets/images/features/metaverse-study-abroad-image-03-03.png';
import FeaturesSectionImage04 from '../assets/images/features/metaverse-study-abroad-image-04-02.png';
import FeaturesSectionImage05 from '../assets/images/features/metaverse-study-abroad-image-05.jpeg';
import FeaturesSectionImage06 from '../assets/images/features/fondi-logo.png';

const MetaverseStudyAbroadSection = () => {
  const metaversestudyabroad = [
    { id: 1, title: '専属Buddyとのセッション', description: '毎日15分、英会話パートナー(専属Buddy)と英会話セッションができます。', image: FeaturesSectionImage01 },
    { id: 2, title: '24時間365日', description: '最先端AIといつでも英会話レッスンが可能です。', image: FeaturesSectionImage02 },
    { id: 3, title: '同時アクセス多数', description: '全世界の英語学習者とコミュニケーションをとることができます。', image: FeaturesSectionImage03 },
    { id: 4, title: '学校専用カスタム', description: '学校の生徒のみが受けられるAIロールプレイングと専用エリアに入ることができます。', image: FeaturesSectionImage04 },
    { id: 5, title: '継続特典', description: '4ヶ月目からの継続は任意ですが、通常の契約時よりも毎月1,000円お得になります。', image: FeaturesSectionImage05 },
  ];

  return (
    <div className='metaverse-study-abroad-section'>
      <h2 className='metaverse-study-abroad-title'>Metaverse Study Abroad</h2>
      <p>メタバースで手軽に英会話が学習できます。</p>
      <div className='metaverse-study-abroad-grid'>
        {metaversestudyabroad.map((metaversestudyabroad) => (
          <div key={metaversestudyabroad.id} className='metaverse-study-abroad-item'>
            <img src={metaversestudyabroad.image} alt={metaversestudyabroad.title} className='metaverse-study-abroad-image' />
            <h3><i class="fa-solid fa-circle"></i>{metaversestudyabroad.title}</h3>
            <p>{metaversestudyabroad.description}</p>
          </div>
        ))}
      </div>

      <p className='fondi-logo'>Powerd by</p><a href="https://www.fondi.fun/top/ja" target="_blank" rel="noopener noreferrer"><span><img src={FeaturesSectionImage06} alt='fondi logo' className='fondi-logo-image'/></span></a>

    </div>
  );
};

export default MetaverseStudyAbroadSection;