import React from 'react';
import './SchoolMeetingSection.css';

const meetingURL = "https://meet.google.com/bpe-bize-qnm";
const startDate = new Date("2025-01-09");
const endDate = new Date("2025-03-20");

const generateThursdays = () => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    if (currentDate.getDay() === 4) { // Thursday
      dates.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

const SchoolMeetingSection = () => {
  const thursdays = generateThursdays().filter((date) => {
    // 今日より前の日付を除外
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 時間をリセットして日付のみ比較
    return date >= today;
  });

  const handleAddToGoogleCalendar = (date) => {
    const formattedDate = date.toISOString().replace(/-|:|\.\d+/g, "");
    const startTime = `${formattedDate.slice(0, 8)}T190000Z`; // 19:00 UTC
    const endTime = `${formattedDate.slice(0, 8)}T200000Z`; // 20:00 UTC
    const calendarURL = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=学校説明会&dates=${startTime}/${endTime}&details=学校説明会にご参加ください。Google Meet URL: ${encodeURIComponent(
      meetingURL
    )}&location=Online&trp=false`;

    window.open(calendarURL, "_blank", "noopener,noreferrer");
  };

  const handleAddToAppleCalendar = (date) => {
    const formattedDate = (d) =>
      d.toISOString().replace(/-|:|\.\d+/g, "").slice(0, 15);
    const startTime = `${formattedDate(date)}Z`;
    const endTime = `${formattedDate(new Date(date.getTime() + 3600000))}Z`; // +1 hour
    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your App//EN
BEGIN:VEVENT
UID:${Date.now()}@yourapp.com
DTSTAMP:${startTime}
DTSTART:${startTime}
DTEND:${endTime}
SUMMARY:学校説明会
DESCRIPTION:学校説明会にご参加ください。Google Meet URL: ${meetingURL}
LOCATION:オンライン
END:VEVENT
END:VCALENDAR`.trim();

    const blob = new Blob([icsContent], { type: "text/calendar" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "event.ics";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="school-meeting-section">
      <h2 className="section-title">Orientation</h2>
      <p className="section-description">
        毎週木曜日、オンラインで学校説明会を開催しています。
      </p>
      <ul className="date-list">
        {thursdays.map((date, index) => (
          <li key={index} className="date-item">
            <span className="date-text">
              {date.toLocaleDateString("ja-JP", {
                year: "numeric",
                month: "long",
                day: "numeric",
                weekday: "long",
              })}
            </span>
            <p className="time-text">19:00〜19:30</p>
            <button
              className="add-to-calendar-btn"
              onClick={() => handleAddToGoogleCalendar(date)}
            >
              Googleカレンダーに追加
            </button>
            <button
              className="add-to-calendar-btn"
              onClick={() => handleAddToAppleCalendar(date)}
            >
              Appleカレンダーに追加
            </button>
          </li>
        ))}
      </ul>
      <p className="meeting-link">
        <a href={meetingURL} target="_blank" rel="noopener noreferrer">
          Google Meetに参加する
        </a>
      </p>
    </section>
  );
};

export default SchoolMeetingSection;