import React, { useEffect, useState } from 'react';
import ToggleMenuCostTable from './ToggleMenuCostTable';
import './CostTable.css';

const courses = [
    { name: "AI開発者", cost: "795,000円", subsidy: "238,500円", campaign: "397,500" },
    { name: "データサイエンティスト", cost: "795,000円", subsidy: "238,500円", campaign: "397,500" },
    { name: "ゲームアプリ開発者", cost: "795,000円", subsidy: "238,500円", campaign: "397,500" },
    { name: "CG・アニメーションクリエイター", cost: "795,000円", subsidy: "238,500円", campaign: "397,500" },
    { name: "メタバースクリエイター", cost: "755,000円", subsidy: "226,500円", campaign: "377,500" },
    { name: "Webアプリ開発者", cost: "695,000円", subsidy: "208,500円", campaign: "347,500" },
    { name: "Webサイトクリエイター", cost: "595,000円", subsidy: "178,500円", campaign: "297,500" },
    { name: "クリエイティブデザイン", cost: "595,000円", subsidy: "178,500円", campaign: "297,500" },
    { name: "デジタルツインマスター", cost: "1,055,000円", subsidy: "495,000円", campaign: "527,500" },
];

const CostTable = () => {

    const [showArrow, setShowArrow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const costTableWrapper = document.querySelector('.cost-table-wrapper');
            const scrollLeft = costTableWrapper.scrollLeft;
            const maxScrollLeft = costTableWrapper.scrollWidth - costTableWrapper.clientWidth;

            // スクロール領域が画面内に収まる場合も矢印を非表示に
            if (costTableWrapper.scrollWidth <= costTableWrapper.clientWidth) {
                setShowArrow(false);
            } else if (scrollLeft >= maxScrollLeft) {
                setShowArrow(false); // 右端に到達したら矢印を非表示
            } else {
                setShowArrow(true); // スクロールできる場合のみ矢印を表示
            }
        };

        const costTableWrapper = document.querySelector('.cost-table-wrapper');
        costTableWrapper.addEventListener('scroll', handleScroll);

        // 初回レンダリング時にも確認
        handleScroll();

        return () => {
            costTableWrapper.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="cost-section">
            <h2>Cost Table</h2>
                <p>コースの費用は以下の通りです。</p>
                <div className="cost-table-wrapper">
                <table className="cost-table">
                    <thead>
                    <tr>
                        <th>コース名</th>
                        <th>費用</th>
                        {/* <th>実質負担額（70%補助対象者）</th> */}
                        <th>キャンペーン価格(40名定員)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {courses.map((course, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                        <td>{course.name}</td>
                        <td>{course.cost}</td>
                        {/* <td>{course.subsidy}</td> */}
                        <td>{course.campaign}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {showArrow && <i className="scroll-icon fa fa-arrow-right"></i>} {/* スクロールアイコン */}

            {/* <ToggleMenuCostTable /> */}

        </div>
    );
};

export default CostTable;